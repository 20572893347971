/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { createWorker, PSM } from "tesseract.js";

import IcUser from "../../assets/icons/ic-user.svg";
import IcPhone from "../../assets/icons/ic-phone.svg";
import icLocation from "../../assets/icons/ic-location.svg";
import IcEmail from "../../assets/icons/ic-email.svg";
import IcCalender from "../../assets/icons/ic-calender.svg";
// import IcWhiteArrow from "../../assets/icons/ic-right-arrow-primary.svg";
import { Button, Dropdown, InputText } from "../../component";
import { useHeaderContext } from "../../context";
import { useCountryCodeList, useOrgList } from "../../services/donation";
import { validateEmail } from "../../utils/validate";
import DatePicker from "react-datepicker";
import subYears from "date-fns/subYears";
import { getYear, getMonth } from "date-fns";

let yearDropdown = function generateArrayOfYears() {
  var max = getYear(subYears(new Date(), 18));
  var min = max - 40;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

const years = yearDropdown();
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SignUp = () => {
  const navigate = useNavigate();
  // const { state } = useLocation();
  // const InputRef = useRef(null);
  const { data, isFetching } = useOrgList();
  const countryCodeQuery = useCountryCodeList();
  const headerCtx = useHeaderContext();
  const [donationOrg, setDonationOrg] = useState([]);
  const [donationOrgErr, setDonationOrgErr] = useState(false);
  const [selectedDonationOrg, setSelectedDonationOrg] = useState({
    display_name: "select",
  });

  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [firstName, setFirstName] = useState(headerCtx.signUpData.first_name);
  const [firstNameErr, setFirstNameErr] = useState(false);

  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [LastName, setLastName] = useState(headerCtx.signUpData.last_name);
  const [LastNameErr, setLastNameErr] = useState(false);

  const [middleNameFocus, setMiddleNameFocus] = useState(false);
  const [middleName, setMiddleName] = useState(
    headerCtx.signUpData.middle_name
  );

  const [originFocus, setOriginFocus] = useState(false);
  const [originName, setOriginName] = useState(
    headerCtx.signUpData.gam_village
  );

  const [mobileNo, setMobileNo] = useState(false);
  const [mobile, setMobile] = useState(headerCtx.signUpData.phone);
  const [mobileData, setMobileData] = useState([]);
  const [mobileCode, setMobileCode] = useState(
    headerCtx.signUpData.country_code !== ""
      ? {
          value: headerCtx.signUpData.country_code,
          label: headerCtx.signUpData.country_code,
        }
      : {
          value: "--",
          label: "--",
        }
  );
  const [mobileErr, setMobileErr] = useState(false);

  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValue, setEmailValue] = useState(headerCtx.signUpData.email);
  const [emailValueErr, setEmailValueErr] = useState(false);

  const [dateFocus, setDateFocus] = useState(false);
  const [dateValue, setDateValue] = useState(
    headerCtx.signUpData.birth_date !== ""
      ? headerCtx.signUpData.birth_date
      : null
  );
  const [dateValueErr, setDateValueErr] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      if (data?.status) {
        setDonationOrg(data.data);
        if (
          headerCtx.signUpData.organization_id &&
          headerCtx.signUpData.organization_id !== ""
        ) {
          let orgData = data.data.find(
            (i) => i.id === headerCtx.signUpData.organization_id
          );
          setSelectedDonationOrg(orgData);
        }
      } else {
        // ToastMsg(data ? data.message : "something went wrong", "error");
      }
    } else {
      // ToastMsg("fetching...", "info");
    }
  }, [isFetching]);

  useEffect(() => {
    if (!countryCodeQuery.isFetching) {
      if (countryCodeQuery.isSuccess) {
        const codeData = countryCodeQuery.data.data;
        if (Array.isArray(codeData)) {
          let entryData = [];
          codeData.forEach((i) => {
            entryData.push({ label: i.dial_code, value: i.dial_code });
          });
          // setMobileCode(entryData[0]);
          setMobileData(entryData);
        }
      }
    }
  }, [countryCodeQuery.isFetching]);

  useEffect(() => {
    headerCtx.setHeader("Sign Up");
    headerCtx.setIsBack(true);
  }, []);

  const onCheckFields = () => {
    let buttonDisable = false;
    if (selectedDonationOrg?.display_name === "select") {
      buttonDisable = true;
      setDonationOrgErr("Please select organization");
    }
    if (firstName === "") {
      buttonDisable = true;
      setFirstNameErr("Please enter first name");
    } else if (firstName.trim().length < 3) {
      buttonDisable = true;
      setFirstNameErr("Please enter minimum 3 character");
    }
    if (LastName === "") {
      buttonDisable = true;
      setLastNameErr("Please enter last name");
    } else if (LastName.trim().length < 3) {
      buttonDisable = true;
      setLastNameErr("Please enter minimum 3 character");
    }
    if (mobileCode.value === "--") {
      buttonDisable = true;
      setMobileErr("Please select country code");
    } else if (mobile.length !== 10) {
      buttonDisable = true;
      setMobileErr("Please enter mobile number");
    }
    if (dateValue === "" || dateValue === null) {
      buttonDisable = true;
      setDateValueErr("Please enter birth date");
    }

    if (emailValue === "") {
      buttonDisable = true;
      setEmailValueErr("Please enter email");
    } else if (!validateEmail(emailValue)) {
      buttonDisable = true;
      setEmailValueErr("Please enter valid email");
    }
    if (!buttonDisable) {
      onContinue();
    }
    // setButtonDisabled(buttonDisable);
  };

  const onContinue = () => {
    const userData = {
      first_name: firstName,
      last_name: LastName,
      middle_name: middleName,
      gam_village: originName,
      email: emailValue,
      organization_id: selectedDonationOrg.id,
      country_code: mobileCode.value,
      phone: mobile,
      birth_date: dateValue,
    };
    headerCtx.setSignUpData({ ...headerCtx.signUpData, ...userData });
    navigate("/auth/addressInfo", { state: userData });
  };

  return (
    <div className="flex flex-col items-center w-full min-h-[100vh]">
      <div className="w-full max-w-2xl mb-3">
        <label className="text-3xl font-bold text-white">Personal Info</label>
      </div>
      <div
        className={`px-5 pt-5 pb-8 shadow-xl mb-5 rounded-xl bg-primaryBg w-full max-w-2xl z-10`}
      >
        {/* <div className="flex w-full mt-2"> */}
        <Dropdown
          // disabled={donationOrg.length === 1}
          isError={donationOrgErr}
          items={donationOrg}
          title={"Donation To*"}
          showTitle={false}
          onChange={(e) => {
            setDonationOrgErr(false);
            setSelectedDonationOrg(e);
          }}
          value={selectedDonationOrg}
          showValue={selectedDonationOrg.display_name}
        />
        {/* </div> */}
        <div className="flex w-full mt-3">
          <label
            className={`border-b ${
              firstNameErr
                ? "border-error"
                : firstNameFocus
                ? "border-lineColor"
                : "border-gray-300"
            } text-sm text-greyOut mt-4 pb-2 pr-2 ${
              firstNameErr && "animate-pulse_finite"
            }`}
          >
            <img src={IcUser} className="h-5 object-contain" alt="p" />
          </label>
          <InputText
            id={"first name"}
            isError={firstNameErr}
            onFocus={() => setFirstNameFocus(true)}
            onBlur={() => setFirstNameFocus(false)}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={firstName}
            placeholder={"First Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setFirstNameErr(false);
                setFirstName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-3">
          <label
            className={`border-b ${
              middleNameFocus ? "border-lineColor" : "border-gray-300"
            } text-sm text-greyOut mt-4 pb-2 pr-2`}
          >
            <img src={IcUser} className="h-5 object-contain" alt="p" />
          </label>
          <InputText
            id={"middle name"}
            onFocus={() => setMiddleNameFocus(true)}
            onBlur={() => setMiddleNameFocus(false)}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={middleName}
            placeholder={"Middle Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setMiddleName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-3">
          <label
            className={`border-b ${
              LastNameErr
                ? "border-error"
                : lastNameFocus
                ? "border-lineColor"
                : "border-gray-300"
            } text-sm text-greyOut mt-4 pb-2 pr-2 ${
              LastNameErr && "animate-pulse_finite"
            }`}
          >
            <img src={IcUser} className="h-5 object-contain" alt="p" />
          </label>
          <InputText
            id={"last name"}
            isError={LastNameErr}
            onFocus={() => setLastNameFocus(true)}
            onBlur={() => setLastNameFocus(false)}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={LastName}
            placeholder={"Last Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setLastNameErr(false);
                setLastName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-3">
          <label
            className={`border-b ${
              originFocus ? "border-lineColor" : "border-gray-300"
            } text-sm text-greyOut mt-4 pb-2 pr-2`}
          >
            <img src={icLocation} className="h-5 object-contain" alt="p" />
          </label>
          <InputText
            id={"india origin"}
            onFocus={() => setOriginFocus(true)}
            onBlur={() => setOriginFocus(false)}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={originName}
            placeholder={"India Origin"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setOriginName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-3">
          <label
            className={`border-b ${
              emailValueErr
                ? "border-error"
                : emailFocus
                ? "border-lineColor"
                : "border-gray-300"
            } text-sm text-greyOut mt-4 pb-2 pr-2 ${
              emailValueErr && "animate-pulse_finite"
            }`}
          >
            <img src={IcEmail} className="h-5 object-contain" alt="p" />
          </label>
          <InputText
            id={"email"}
            isError={emailValueErr}
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"email"}
            value={emailValue}
            placeholder={"Email"}
            onChange={(e) => {
              setEmailValueErr(false);
              setEmailValue(e.target.value);
            }}
          />
        </div>
        <div className="flex w-full mt-3">
          <label
            className={`border-b ${
              mobileErr
                ? "border-error"
                : mobileNo
                ? "border-lineColor"
                : "border-gray-300"
            } text-sm text-greyOut mt-4 pb-2 pr-2 ${
              mobileErr && "animate-pulse_finite"
            }`}
          >
            <img src={IcPhone} className="h-5 object-contain" alt="p" />
          </label>
          <Dropdown
            // disabled={donationOrg.length === 0}
            isError={mobileErr}
            showError={false}
            items={mobileData}
            onChange={(e) => {
              // setDonationOrgErr(false);
              setMobileErr(false);
              setMobileCode(e);
            }}
            value={mobileCode}
            showValue={mobileCode.label}
            extraClass={`w-14 z-auto ${mobileNo && "border-lineColor"}`}
          />
          <InputText
            id={"mobile"}
            isError={mobileErr}
            onFocus={() => setMobileNo(true)}
            onBlur={() => setMobileNo(false)}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"number"}
            value={mobile}
            maxLength={10}
            minLength={10}
            placeholder={"Phone Number"}
            onChange={(e) => {
              setMobileErr(false);
              setMobile(e.target.value);
            }}
          />
        </div>
        <div className="flex w-full relative mt-3">
          <label
            className={`border-b ${
              dateValueErr
                ? "border-error"
                : dateFocus
                ? "border-lineColor"
                : "border-gray-300"
            } text-sm text-greyOut mt-4 pb-2 pr-2 ${
              dateValueErr && "animate-pulse_finite"
            }`}
          >
            <img src={IcCalender} className="h-5 object-contain" alt="p" />
          </label>
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => {
              return (
                <div className="m-2 flex justify-between">
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {"<"}
                  </button>
                  <div className="flex">
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {getYear(date) === getYear(subYears(new Date(), 18))
                        ? months
                            .slice(0, new Date().getMonth() + 1)
                            .map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))
                        : months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                    </select>
                  </div>
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {">"}
                  </button>
                </div>
              );
            }}
            id={"dob"}
            maxDate={subYears(new Date(), 18)}
            selected={dateValue}
            onChange={(date) => {
              setDateValueErr(false);
              setDateValue(date);
            }}
            placeholderText="Date of Birth"
            onCalendarOpen={() => setDateFocus(true)}
            onCalendarClose={() => setDateFocus(false)}
            calendarClassName="z-100"
            // wrapperClassName="Z-100"
            // monthClassName={"z-100"}
            className={`border-b bg-primaryBg ${
              dateValueErr ? "border-error" : "border-gray-300"
            } text-sm text-title focus:${
              dateValueErr ? "border-error" : "border-lineColor"
            } mt-4 pb-2 w-full`}
          />
          {dateValueErr && (
            <label className="text-xs absolute -bottom-4 text-error ml-7">
              {dateValueErr}
            </label>
          )}
        </div>
        {/* <input
          ref={InputRef}
          type="file"
          className=""
          onChange={handleImageSelect}
        /> */}
      </div>
      <Button
        // disabled={isButtonDisabled}
        onClick={() => onCheckFields()}
        extraClass={`max-w-2xl ${dateFocus ? "-z-1" : "z-10"}`}
        title={"Continue"}
        white
      />
      {/* <div
        onClick={() => ScanDoc()}
        className="p-3 rounded-full animate-pulse bg-orange-600 text-lg fixed bottom-0 right-0 m-5 text-white"
      >
        Scan Doc
      </div> */}
    </div>
  );
};

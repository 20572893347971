/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import userDefault from "../../assets/images/defaultUser.png";
import { Button, InputText } from "../../component";
import { getWithExpiry, setWithExpiry, ToastMsg } from "../../utils";
// import { useDonationList } from "../../services/donation";
import { useHeaderContext, useLoadingContext } from "../../context";
import { UpdateUserAPI } from "../../services/auth";
import { useNavigate } from "react-router-dom";

export const PersonalInfo = () => {
  const member = getWithExpiry("member");
  // const { data, isFetching } = useDonationList();
  const navigate = useNavigate();
  const headerCtx = useHeaderContext();

  const { setLoading, Loading } = useLoadingContext();

  // const [donationOrg, setDonationOrg] = useState([]);
  // const [selectedDonationOrg, setSelectedDonationOrg] = useState({
  //   organization_name: "select",
  // });
  const [firstName, setFirstName] = useState(member.first_name || "");
  const [firstNameErr, setFirstNameErr] = useState(false);

  const [lastName, setLastName] = useState(member.last_name || "");
  const [LastNameErr, setLastNameErr] = useState(false);

  const [middleName, setMiddleName] = useState(member.middle_name || "");

  const [originName, setOriginName] = useState(member.gam_village || "");

  const [emailValue, setEmailValue] = useState(member.email || "");
  const [emailValueErr, setEmailValueErr] = useState(false);

  useEffect(() => {
    headerCtx.setHeader("Personal Info");
    headerCtx.setIsBack(true);
    headerCtx.setMainHeader(false);
    headerCtx.setSearchBar(false);
  }, []);

  // useEffect(() => {
  //   if (!isFetching) {
  //     if (data?.status) {
  //       setDonationOrg(data.data.organizations);
  //       setSelectedDonationOrg(data.data.organizations[0]);
  //     } else {
  //     }
  //   } else {
  //   }
  // }, [isFetching]);

  const onCheckFields = () => {
    const member = getWithExpiry("member");
    setLoading(true);
    let buttonDisable = false;
    let toastMessage = "can't update similar value";
    let isSimilar = [];
    if (firstName === "") {
      buttonDisable = true;
      setFirstNameErr("Please enter first name");
    } else if (firstName.trim().length < 3) {
      buttonDisable = true;
      setFirstNameErr("Please enter minimum 3 character");
    } else if (firstName === member.first_name) {
      isSimilar.push(1);
    }
    if (lastName === "") {
      buttonDisable = true;
      setLastNameErr("Please enter last name");
    } else if (lastName.trim().length < 3) {
      buttonDisable = true;
      setLastNameErr("Please enter minimum 3 character");
    } else if (lastName === member.last_name) {
      isSimilar.push(2);
    }
    if (middleName === member.middle_name || member.middle_name === null) {
      isSimilar.push(8);
    }
    if (originName === member.gam_village || member.gam_village === null) {
      isSimilar.push(10);
    }
    if (!buttonDisable) {
      if (isSimilar.length === 10) {
        ToastMsg(toastMessage, "info");
        setLoading(false);
      } else {
        onContinue();
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    setLoading(true);
    const data = {
      memberID: member.memberId,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      gam_village: originName,
      email: emailValue,
      organization_id: member.organization.id,
    };
    const res = await UpdateUserAPI(data);
    if (res.status) {
      ToastMsg(res.message, "success");
      setWithExpiry("member", res.data);
      setLoading(false);
      navigate("/profile");
    } else {
      setLoading(false);
      ToastMsg(res.message, "error");
    }
  };

  return (
    <div className="flex flex-1 flex-col items-center max-w-2xl w-full self-center">
      <div
        className={`px-5 pt-5 pb-8 shadow-xl mb-5 rounded-xl bg-primaryCard w-full max-w-2xl z-10`}
      >
        <div className="w-full items-center justify-center flex mt-9 flex-col mb-12">
          <div className="bg-lineColor h-24 w-24 rounded-full mb-4 overflow-hidden">
            <img
              src={userDefault}
              alt="user"
              className="h-24 w-24 object-cover"
            />
          </div>
          <label className="text-primary text-base text-center">
            Member Id# {member?.memberId}
          </label>
        </div>
        {/* <div className="flex w-full mt-2"> */}
        {/* <Dropdown
        disabled={donationOrg.length === 1}
        items={donationOrg}
        onChange={setSelectedDonationOrg}
        value={selectedDonationOrg}
        showValue={selectedDonationOrg.organization_name}
        title={""}
      /> */}
        {/* </div> */}
        <div className="flex w-full mt-2">
          <InputText
            id={"first name"}
            isError={firstNameErr}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={firstName}
            placeholder={"First Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setFirstNameErr(false);
                setFirstName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-2">
          <InputText
            id={"middle name"}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={middleName}
            placeholder={"Middle Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setMiddleName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-2">
          <InputText
            id={"last name"}
            isError={LastNameErr}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={lastName}
            placeholder={"Last Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setLastNameErr(false);
                setLastName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-2">
          <InputText
            id={"india origin"}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={originName}
            placeholder={"India Origin"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setOriginName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-2">
          <InputText
            id={"email"}
            isError={emailValueErr}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"email"}
            value={emailValue}
            placeholder={"Email"}
            onChange={(e) => {
              setEmailValueErr(false);
              setEmailValue(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex self-end items-end justify-between w-4/5">
        <Button
          disabled={Loading}
          onClick={() => navigate("/profile")}
          extraClass={`w-[48%]`}
          title={"Cancel"}
          secondary
        />
        <Button
          disabled={Loading}
          onClick={() => onCheckFields()}
          extraClass={`w-[48%]`}
          title={"Update"}
        />
      </div>
    </div>
  );
};

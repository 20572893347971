/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HistoryCard } from "../../component";
import {
  useHeaderContext,
  useLoadingContext,
  useModalContext,
} from "../../context";
import { useDonationHistory } from "../../services/donation";
import IcSearch from "../../assets/icons/ic-search.svg";
import { PaymentDetails } from "../../services/payment";
import { ToastMsg } from "../../utils";
import { HistoryModal } from "./historyModal";

export const History = () => {
  const navigate = useNavigate();
  const loadingCtx = useLoadingContext();
  const { data, isFetching, isLoading } = useDonationHistory();
  const { setOpen, setOkay, ModalData } = useModalContext();
  const [historyData, setHistoryData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [extra, setExtra] = useState(0);
  const [itemIndex, setItemIndex] = useState(-1);
  const headerCtx = useHeaderContext();

  const onChangeText = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    headerCtx.setSearchBar(
      <div className="h-full w-[90vw] max-w-2xl bg-white z-50 border border-lineColor overflow-hidden rounded-md px-4 flex items-center">
        <img src={IcSearch} className="h-6 w-6 object-contain" alt="search" />
        <input
          value={searchText}
          onChange={onChangeText}
          className={`h-full w-full text-sm text-title pl-2`}
          placeholder={"Search invoice Number, Amount, Event, Organization"}
        />
      </div>
    );
  }, [searchText]);

  useEffect(() => {
    headerCtx.setHeader("History");
    headerCtx.setIsBack(false);
    headerCtx.setMainHeader(true);
  }, []);

  useEffect(() => {
    if (!isFetching) {
      if (data.status) {
        setHistoryData(data.data);
        setExtra(extra + 1);
      } else {
        if (data.statusCode === 401) {
          headerCtx.setSearchBar(false);
          navigate("/auth/signin");
        }
      }
    }
  }, [isFetching, isLoading]);

  if (isLoading) {
    loadingCtx.setLoading(true);
    return <div />;
  } else {
    loadingCtx.setLoading(false);
  }

  const onClickHistoryCard = async (e, i, k) => {
    setItemIndex(k);
    e.stopPropagation();
    const body = { payment_intent_key: i.payment.gateway_paymentIntentKey };
    const res = await PaymentDetails(body);
    if (res.status) {
      setOkay(false);
      setOpen(true);
      ModalData(
        <HistoryModal
          item={{ ...res.data.payment_details, ...i }}
          link={res.data.link}
          setOkay={setOkay}
          setOpen={setOpen}
        />
      );
      setItemIndex(-1);
    } else {
      ToastMsg(res.message, "error");
      setItemIndex(-1);
    }
  };

  return (
    <div className="pb-5 flex flex-col items-center">
      {historyData?.length > 0 ? (
        <div className={`shadow-md mb-5 px-5 bg-primaryCard max-w-2xl w-full`}>
          {React.Children.toArray(
            historyData.map((i, k, arr) => {
              if (!i.payment.amount.toString().includes(searchText)) {
                if (!i.invoice_no.includes(searchText.toLowerCase())) {
                  if (
                    !i?.organization?.display_name
                      .toString()
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    if (
                      !i.donation_category.category_name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    ) {
                      if (
                        !i.donation_event.event_name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      ) {
                        return null;
                      }
                    }
                  }
                }
              }
              return (
                <HistoryCard
                  isLoading={itemIndex === k}
                  item={i}
                  extraClass={`${
                    arr.length !== k + 1 && "border-b border-b-primary"
                  }`}
                  onClick={(e) => onClickHistoryCard(e, i, k)}
                />
              );
            })
          )}
        </div>
      ) : (
        <div className="text-black text-base font-semibold text-center max-w-2xl w-full">
          No History Found
        </div>
      )}
    </div>
  );
};

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Button, InputText } from "../../component";
import { useHeaderContext, useLoadingContext } from "../../context";
import { getWithExpiry, setWithExpiry, ToastMsg } from "../../utils";
import { UpdateUserAPI } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { GetCountry } from "../../services/donation";

export const UserAddressInfo = () => {
  const navigate = useNavigate();
  const member = getWithExpiry("member");
  const headerCtx = useHeaderContext();
  const { setLoading, Loading } = useLoadingContext();

  const [zipCheck, setZipCheck] = useState(false);

  const [address1, setAddress1] = useState(member.address_line1 || "");
  const [address1Err, setAddress1Err] = useState(false);

  const [address2, setAddress2] = useState(member.address_line2 || "");

  const [city, setCity] = useState(member.city || "");
  const [cityErr, setCityErr] = useState(false);

  const [country, setCountry] = useState(member.country || "");
  const [countryErr, setCountryErr] = useState(false);

  const [zip, setZip] = useState(member.zip || "");
  const [zipErr, setZipErr] = useState(false);

  const [stateValue, setStateValue] = useState(member.state || "");
  const [stateValueErr, setStateValueErr] = useState(false);

  useEffect(() => {
    headerCtx.setHeader("Address Info");
    headerCtx.setIsBack(true);
    headerCtx.setMainHeader(false);
    headerCtx.setSearchBar(false);
  }, []);

  const onCheckFields = () => {
    const member = getWithExpiry("member");
    setLoading(true);
    let buttonDisable = false;
    let toastMessage = "can't update similar value";
    let isSimilar = [];

    if (address1 === "") {
      buttonDisable = true;
      setAddress1Err("Please enter address");
    } else if (address1.trim().length < 3) {
      buttonDisable = true;
      setAddress1Err("Please enter minimum 3 character");
    } else if (address1 === member.address_line1) {
      isSimilar.push(4);
    }
    if (city === "") {
      buttonDisable = true;
      setCityErr("Please enter city");
    } else if (city.trim().length < 2) {
      buttonDisable = true;
      setCityErr("Please enter valid city");
    } else if (city === member.city) {
      isSimilar.push(5);
    }
    if (stateValue.length !== 2) {
      buttonDisable = true;
      setStateValueErr("Please enter valid state");
    } else if (stateValue === member.state) {
      isSimilar.push(6);
    }
    if (zip.length !== 5 && !zipCheck) {
      buttonDisable = true;
      setZipErr("Please enter valid zip code");
    } else if (zip == member.zip) {
      isSimilar.push(7);
    }

    if (address2 === member.address_line2 || member.address_line2 === null) {
      isSimilar.push(9);
    }

    if (!buttonDisable) {
      if (isSimilar.length === 10) {
        ToastMsg(toastMessage, "info");
        setLoading(false);
      } else {
        onContinue();
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    setLoading(true);
    const data = {
      memberID: member.memberId,
      address_line1: address1,
      address_line2: address2,
      city: city,
      country: country,
      state: stateValue,
      zip: zip,
    };
    const res = await UpdateUserAPI(data);
    if (res.status) {
      ToastMsg(res.message, "success");
      console.log("DATA", res.data);
      setWithExpiry("member", res.data);
      setLoading(false);
      navigate("/profile");
    } else {
      setLoading(false);
      ToastMsg(res.message, "error");
    }
  };

  const getCountryData = async (text) => {
    const res = await GetCountry({ zip: text.trim() });
    // console.log("res", res);
    if (res.status) {
      setCity(res.data.city);
      setStateValue(res.data.state);
      setCountry(res.data.country);
      setStateValueErr(false);
      setCityErr(false);
      setZipErr(false);
      setCityErr(false);
      setLoading(false);
      setZipCheck(true);
    } else {
      setZipCheck(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col items-center max-w-2xl w-full self-center">
      <div
        className={`px-5 pb-8 shadow-xl mb-5 rounded-md bg-primaryCard w-full max-w-2xl`}
      >
        {/* asdsad */}
        <div className="flex w-full mt-2">
          <InputText
            id={"Address 1"}
            isError={address1Err}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={address1}
            placeholder={"Address 1"}
            onChange={(e) => {
              setAddress1Err(false);
              setAddress1(e.target.value);
            }}
          />
        </div>
        <div className="flex w-full mt-2">
          <InputText
            id={"Address 2"}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={address2}
            placeholder={"Address 2"}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
          />
        </div>
        <div className="flex w-full mt-2">
          <div className="flex w-full mr-2">
            <InputText
              id={"zip"}
              isError={zipErr}
              maxLength={5}
              extraClassName={"mt-4 pb-2 w-full"}
              type={"number"}
              value={zip}
              placeholder={"zip"}
              onChange={(e) => {
                if (e.target.value.trim().length <= 5) {
                  setZipErr(false);
                  setZip(e.target.value);
                  e.target.value.trim().length === 5 &&
                    getCountryData(e.target.value);
                }
              }}
            />
          </div>
          <div className="flex w-full ml-2">
            <InputText
              maxLength={2}
              isError={stateValueErr}
              id={"state"}
              extraClassName={"mt-4 pb-2 w-full"}
              type={"text"}
              value={stateValue}
              placeholder={"State"}
              onChange={(e) => {
                if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                  setStateValueErr(false);
                  setStateValue(e.target.value.toUpperCase());
                }
              }}
            />
          </div>
        </div>
        <div className="flex w-full mt-2">
          <InputText
            id={"country"}
            isError={countryErr}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={country}
            placeholder={"Country"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setCountryErr(false);
                setCountry(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex w-full mt-2">
          <InputText
            id={"city"}
            isError={cityErr}
            extraClassName={"mt-4 pb-2 w-full"}
            type={"text"}
            value={city}
            placeholder={"City"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setCityErr(false);
                setCity(e.target.value);
              }
            }}
          />
        </div>
      </div>
      <div className="flex self-end items-end justify-between w-4/5">
        <Button
          disabled={Loading}
          onClick={() => navigate("/profile")}
          extraClass={`w-[48%]`}
          title={"Cancel"}
          secondary
        />
        <Button
          disabled={Loading}
          onClick={() => onCheckFields()}
          extraClass={`w-[48%]`}
          title={"Update"}
        />
      </div>
    </div>
  );
};

export const userDefault = {
  first_name: "",
  last_name: "",
  middle_name: "",
  gam_village: "",
  email: "",
  organization_id: "",
  country_code: "",
  phone: "",
  birth_date: "",
  address_line1: "",
  address_line2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
};

export const donationDefault = {};

import { useQuery } from "@tanstack/react-query";
import { BaseUrl } from "../config/config";
import { getWithExpiry } from "../utils";

const BASE_URL = BaseUrl;

export function useDonationList() {
  return useQuery(
    ["donation-list"],
    async () =>
      await fetch(`${BASE_URL}/api/donation-list`)
        .then((response) => response.json())
        .then((json) => json)
        .catch((err) => err)
  );
}

export function useOrgList() {
  return useQuery(
    ["org-list"],
    async () =>
      await fetch(`${BASE_URL}/api/organization-list`)
        .then((response) => response.json())
        .then((json) => json)
        .catch((err) => err)
  );
}

export const GetEventsList = async (data) => {
  return await fetch(`${BASE_URL}/api/event-list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getWithExpiry("token")
        ? "Bearer " + getWithExpiry("token")
        : "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => err);
};

export const GetDonationList = async (data) => {
  return await fetch(`${BASE_URL}/api/category-list`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getWithExpiry("token")
        ? "Bearer " + getWithExpiry("token")
        : "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => err);
};

export const AddDonation = async (data) => {
  return await fetch(`${BASE_URL}/api/donation-add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getWithExpiry("token")
        ? "Bearer " + getWithExpiry("token")
        : "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => err);
};

export const DeleteIntent = async (data) => {
  return await fetch(`${BASE_URL}/api/delete-payment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getWithExpiry("token")
        ? "Bearer " + getWithExpiry("token")
        : "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => err);
};

export function useDonationHistory() {
  const options = {
    method: "GET",
    headers: {
      Authorization: getWithExpiry("token")
        ? "Bearer " + getWithExpiry("token")
        : "",
    },
  };
  return useQuery(
    ["donation-history"],
    async () =>
      await fetch(`${BASE_URL}/api/payment-history`, options)
        .then((response) => response.json())
        .then((json) => json)
        .catch((err) => err)
  );
}

export function useCountryCodeList() {
  const options = {
    method: "GET",
    headers: {
      Authorization: getWithExpiry("token")
        ? "Bearer " + getWithExpiry("token")
        : "",
    },
  };
  return useQuery(
    ["country-code"],
    async () =>
      await fetch(`${BASE_URL}/api/country-code-list`, options)
        .then((response) => response.json())
        .then((json) => json)
        .catch((err) => err)
  );
}

export const GetCountry = async (data) => {
  return await fetch(`${BASE_URL}/api/check-zip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getWithExpiry("token")
        ? "Bearer " + getWithExpiry("token")
        : "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => err);
};
// const Header = {
//   Authorization: getWithExpiry("token")
//     ? "Bearer " + getWithExpiry("token")
//     : "",
// };

// var requestOptions = {
//   method: 'GET',
//   redirect: 'follow'
// };

// fetch("localhost:9000/api/country-code-list", requestOptions)
//   .then(response => response.text())
//   .then(result => console.log(result))
//   .catch(error => console.log('error', error));

// var requestOptions = {
//   method: 'POST',
//   redirect: 'follow'
//   body: JSON.stringify(data),
// };

// fetch("localhost:9000/api/check-zip", requestOptions)
//   .then(response => response.text())
//   .then(result => console.log(result))
//   .catch(error => console.log('error', error));

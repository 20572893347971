/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, InputText } from "../../component";
import { useHeaderContext, useLoadingContext } from "../../context";
import {
  AddDonation,
  GetCountry,
  useCountryCodeList,
} from "../../services/donation";

import { getWithExpiry, ToastMsg } from "../../utils";
import { validateEmail } from "../../utils/validate";

export const DonationDetails = () => {
  const { state } = useLocation();
  const member = getWithExpiry("member");
  const headerCtx = useHeaderContext();
  const navigate = useNavigate();
  const { setLoading } = useLoadingContext();
  const countryCodeQuery = useCountryCodeList();
  const [extra, setExtra] = useState(0);

  const [firstName, setFirstName] = useState(member ? member.first_name : "");
  const [firstNameErr, setFirstNameErr] = useState(false);

  const [lastName, setLastName] = useState(member ? member.last_name : "");
  const [LastNameErr, setLastNameErr] = useState(false);

  const [middleName, setMiddleName] = useState(
    member ? member.middle_name : ""
  );

  const [originName, setOriginName] = useState(
    member ? member.gam_village : ""
  );

  const [zipCheck, setZipCheck] = useState(false);

  const [address1, setAddress1] = useState(member ? member.address_line1 : "");
  const [address1Err, setAddress1Err] = useState(false);

  const [address2, setAddress2] = useState(member ? member.address_line2 : "");

  const [city, setCity] = useState(member ? member.city : "");
  const [cityErr, setCityErr] = useState(false);

  const [country, setCountry] = useState(member ? member.country : "");
  const [countryErr, setCountryErr] = useState("");

  const [mobile, setMobile] = useState(member ? member.phone : "");
  const [mobileData, setMobileData] = useState([]);
  const [mobileCode, setMobileCode] = useState({
    value: member ? member.country_code : "--",
    label: member ? member.country_code : "--",
  });
  const [mobileErr, setMobileErr] = useState(false);

  const [zip, setZip] = useState(member ? member.zip : "");
  const [zipErr, setZipErr] = useState(false);

  const [stateValue, setStateValue] = useState(member ? member.state : "");
  const [stateValueErr, setStateValueErr] = useState(false);

  const [emailValue, setEmailValue] = useState(member ? member.email : "");
  const [emailValueErr, setEmailValueErr] = useState(false);

  useEffect(() => {
    setLoading(false);
    headerCtx.setIsBack(true);
    headerCtx.setHeader("Donation");
    headerCtx.setMainHeader(false);
    headerCtx.setSearchBar(false);
  }, []);

  useEffect(() => {
    if (!countryCodeQuery.isFetching) {
      if (countryCodeQuery.isSuccess) {
        console.log("countryCodeQuery", countryCodeQuery.data);
        const codeData = countryCodeQuery.data.data;
        if (Array.isArray(codeData)) {
          let entryData = [];
          codeData.forEach((i) => {
            entryData.push({ label: i.dial_code, value: i.dial_code });
          });
          // setMobileCode(entryData[0]);
          setMobileData(entryData);
        }
      }
    }
  }, [countryCodeQuery.isFetching]);

  const onCheckFields = (e) => {
    let buttonDisable = false;
    if (firstName === "") {
      buttonDisable = true;
      setFirstNameErr("Please enter name");
    } else if (firstName.trim().length < 3) {
      buttonDisable = true;
      setFirstNameErr("Please enter minimum 3 character");
    }
    if (lastName === "") {
      buttonDisable = true;
      setLastNameErr("Please enter last name");
    } else if (lastName.trim().length < 3) {
      buttonDisable = true;
      setLastNameErr("Please enter minimum 3 character");
    }
    if (emailValue === "") {
      buttonDisable = true;
      setEmailValueErr("Please enter email");
    } else if (!validateEmail(emailValue)) {
      buttonDisable = true;
      setEmailValueErr("Please enter valid email");
    }
    if (address1 === "") {
      buttonDisable = true;
      setAddress1Err("Please enter address");
    } else if (address1.trim().length < 3) {
      buttonDisable = true;
      setAddress1Err("Please enter minimum 3 character");
    }
    if (mobileCode.value === "--") {
      buttonDisable = true;
      setMobileErr("Please select country code");
    } else if (mobile.length !== 10) {
      buttonDisable = true;
      setMobileErr("Please enter mobile number");
    }
    if (city === "") {
      buttonDisable = true;
      setCityErr("Please enter city");
    } else if (city.trim().length < 2) {
      buttonDisable = true;
      setCityErr("Please enter valid city");
    }
    if (country === "") {
      setCountryErr("Please enter country");
      buttonDisable = true;
    } else if (country.trim().length < 2) {
      setCountryErr("Please enter valid country");
      buttonDisable = true;
    }
    if (stateValue.length !== 2) {
      buttonDisable = true;
      setStateValueErr("Please enter state");
    } else if (stateValue.trim().length < 2) {
      buttonDisable = true;
      setStateValueErr("Please enter valid state");
    }
    if (zip.length !== 5 && !zipCheck) {
      buttonDisable = true;
      setZipErr("Please enter valid zip");
    }
    if (!buttonDisable) {
      onSubmitForm(e);
    }
  };

  const onSubmitForm = async (e) => {
    e.stopPropagation();
    setLoading(true);
    const body = {
      ...state,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      gam_village: originName,
      address_line1: address1,
      address_line2: address2,
      city: city,
      state: stateValue,
      donar_country_code: mobileCode.value,
      donar_country: country,
      zip: zip,
      phone: mobile,
      email: emailValue,
    };
    const res = await AddDonation(body);
    if (res.status) {
      setLoading(false);
      navigate("/payment", {
        state: {
          gateway_clientSecret: res.data.gateway_clientSecret,
          stripe_public_key: res.data.stripe_public_key,
        },
      });
      // setButtonDisable(false);
    } else {
      setLoading(false);
      ToastMsg(res.message, "error");
      // setButtonDisable(false);
    }
  };

  const getCountryData = async (text) => {
    setLoading(true);
    const res = await GetCountry({ zip: text.trim() });
    if (res.status) {
      setCity(res.data.city);
      setStateValue(res.data.state);
      setCountry(res.data.country);
      setStateValueErr(false);
      setCityErr(false);
      setZipErr(false);
      setCityErr(false);
      setCountryErr(false);
      setExtra(extra + 1);
      setLoading(false);
      setZipCheck(true);
    } else {
      setZipCheck(false);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col w-full items-center">
      <div
        className={`px-5 pt-2 pb-4 shadow-xl mb-5 rounded-xl bg-primaryCard max-w-2xl w-full`}
      >
        <div className="mt-5 flex" />
        <label className={`text-title font-bold text-base`}>
          {"Donar Details"}
        </label>
        <div className="flex flex-col w-full py-3">
          <label id={"firstname"} className="text-sm font-medium text-primary">
            {`First Name*`}
          </label>
          <InputText
            id={"first name"}
            isError={firstNameErr}
            extraClassName={"pb-2 w-full"}
            type={"text"}
            value={firstName}
            placeholder={"Enter First Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setFirstNameErr(false);
                setFirstName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"middleName"} className="text-sm font-medium text-primary">
            {`Middle Name`}
          </label>
          <InputText
            id={"middle name"}
            extraClassName={" pb-2 w-full"}
            type={"text"}
            value={middleName}
            placeholder={"Enter Middle Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setMiddleName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"lastName"} className="text-sm font-medium text-primary">
            {`Last Name*`}
          </label>
          <InputText
            id={"last name"}
            isError={LastNameErr}
            extraClassName={" pb-2 w-full"}
            type={"text"}
            value={lastName}
            placeholder={"Last Name"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setLastNameErr(false);
                setLastName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"origin"} className="text-sm font-medium text-primary">
            {`India Origin`}
          </label>
          <InputText
            id={"india origin"}
            extraClassName={" pb-2 w-full"}
            type={"text"}
            value={originName}
            placeholder={"Enter India Origin"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setOriginName(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"mobile"} className="text-sm font-medium text-primary">
            {`Phone Number*`}
          </label>
          <div className="flex w-full">
            <Dropdown
              // disabled={donationOrg.length === 0}
              isError={mobileErr}
              showError={false}
              items={mobileData}
              onChange={(e) => {
                // setDonationOrgErr(false);
                setMobileErr(false);
                setMobileCode(e);
              }}
              value={mobileCode}
              showValue={mobileCode.label}
              extraClass={`w-14 z-auto`}
            />
            <InputText
              id={"mobile"}
              isError={mobileErr}
              extraClassName={"mt-4 pb-2 w-full"}
              type={"number"}
              value={mobile}
              maxLength={10}
              minLength={10}
              placeholder={"Phone Number"}
              onChange={(e) => {
                setMobileErr(false);
                setMobile(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"address 1"} className="text-sm font-medium text-primary">
            {`Address 1*`}
          </label>
          <InputText
            id={"Address 1"}
            isError={address1Err}
            extraClassName={" pb-2 w-full"}
            type={"text"}
            value={address1}
            placeholder={"Enter Address 1"}
            onChange={(e) => {
              setAddress1Err(false);
              setAddress1(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"address 2"} className="text-sm font-medium text-primary">
            {`Address 2`}
          </label>
          <InputText
            id={"Address 2"}
            extraClassName={" pb-2 w-full"}
            type={"text"}
            value={address2}
            placeholder={"Enter Address 2"}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
          />
        </div>
        <div className="flex w-full py-3">
          <div className="flex flex-col w-full mr-2">
            <label id={"zip"} className="text-sm font-medium text-primary">
              {`ZIP*`}
            </label>
            <InputText
              id={"zip"}
              isError={zipErr}
              maxLength={5}
              extraClassName={" pb-2 w-full"}
              type={"number"}
              value={zip}
              placeholder={"Enter zip"}
              onChange={(e) => {
                if (e.target.value.trim().length <= 5) {
                  setZipErr(false);
                  setZip(e.target.value);
                  e.target.value.trim().length === 5 &&
                    getCountryData(e.target.value);
                }
              }}
            />
          </div>
          <div className="flex flex-col w-full ml-2">
            <label id={"state"} className="text-sm font-medium text-primary">
              {`State*`}
            </label>
            <InputText
              maxLength={2}
              isError={stateValueErr}
              id={"state"}
              extraClassName={" pb-2 w-full"}
              type={"text"}
              value={stateValue}
              placeholder={"Enter State"}
              onChange={(e) => {
                if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                  setStateValueErr(false);
                  setStateValue(e.target.value.toUpperCase());
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"country"} className="text-sm font-medium text-primary">
            {`Country*`}
          </label>
          <InputText
            id={"country"}
            isError={countryErr}
            extraClassName={"pb-2 w-full"}
            type={"text"}
            value={country}
            placeholder={"Enter Country"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setCountryErr(false);
                setCountry(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"city"} className="text-sm font-medium text-primary">
            {`City*`}
          </label>
          <InputText
            id={"city"}
            isError={cityErr}
            extraClassName={"pb-2 w-full"}
            type={"text"}
            value={city}
            placeholder={"City"}
            onChange={(e) => {
              if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                setCityErr(false);
                setCity(e.target.value);
              }
            }}
          />
        </div>
        <div className="flex flex-col w-full py-3">
          <label id={"email"} className="text-sm font-medium text-primary">
            {`Email*`}
          </label>
          <InputText
            id={"email"}
            isError={emailValueErr}
            extraClassName={" pb-2 w-full"}
            type={"email"}
            value={emailValue}
            placeholder={"Email"}
            onChange={(e) => {
              setEmailValueErr(false);
              setEmailValue(e.target.value);
            }}
          />
        </div>
      </div>
      <Button
        // disabled={buttonDisable}
        title={"Donate Now"}
        onClick={(e) => onCheckFields(e)}
        extraClass={"max-w-2xl w-full"}
      />
    </div>
  );
};

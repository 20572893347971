import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import {
  HeaderContextProvider,
  LoadingContextProvider,
  ModalContextProvider,
} from "./context";
import router from "./routes";
import "react-datepicker/dist/react-datepicker.css";
import { SplashScreen } from "./pages";

const queryClient = new QueryClient();

function App() {
  const [show, setShow] = useState(false);
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    if (window.location.pathname !== "/success") {
      setShow(true);
      let timer1 = setTimeout(() => setShow(false), 2000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, []);

  useEffect(() => {
    window.addEventListener("offline", (e) => {
      setOnline(false);
    });

    window.addEventListener("online", (e) => {
      setOnline(true);
    });
  }, []);

  // const [isShowing, setShowing] = useState(false);
  const isIOS = navigator.userAgent.match(/OS/i) != null;
  if (isIOS) document.body.classList.add("ios-status-bar");
  return (
    <LoadingContextProvider>
      <div
        className={`fixed top-0 w-screen bg-red-900/90 z-[100] pointer-events-none flex items-center justify-center transition-all`}
      >
        <text
          className={`text-xs text-white transition-all ${
            !isOnline ? "flex" : "hidden"
          }`}
        >
          offline
        </text>
      </div>
      <ModalContextProvider>
        <HeaderContextProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <SplashScreen show={show} />
          </QueryClientProvider>
        </HeaderContextProvider>
      </ModalContextProvider>
    </LoadingContextProvider>
  );
}

export default App;

import React from "react";
import { Button } from "../../component";
import { BaseUrl } from "../../config/config";
import { FormatTime } from "../../utils";

export const HistoryModal = ({ item, link, setOkay, setOpen }) => {
  return (
    <div className="p-5 bg-primaryBg max-w-2xl">
      <div
        className={`px-5 pb-5 shadow-md mb-5 pt-2 rounded-md bg-primaryCard flex flex-col items-center`}
      >
        <label className="text-center text-base text-title">
          {`${item.donar_full_name}${
            item.donar_gam_village ? " (" + item.donar_gam_village + ")" : ""
          }`}
        </label>
        <label className="text-center text-base text-greyOut">
          {item.donation_event.event_name +
            " | " +
            item.donation_category.category_name}
        </label>
        <label className="text-center text-base text-greyOut">
          {item.organization.organization_name}
        </label>
        <div
          className={`w-full ${item.donation_date === "" && "animate-pulse"}`}
        >
          <div className="border-b border-gray-200 flex py-3 w-full justify-between">
            <label className="text-center text-sm text-title">
              {"Invoice Number"}
            </label>
            <label className="text-center text-sm text-primary">
              {`#${item.invoice_no}`}
            </label>
          </div>
          <div className="border-b border-gray-200 flex py-3 w-full justify-between">
            <label className="text-center text-sm text-title">
              {"Total Amount"}
            </label>
            <label className="text-center text-sm text-primary">
              {`$ ${Number(item.donation_amount).toFixed(2)}`}
            </label>
          </div>
          <div className="border-b border-gray-200 flex py-3 w-full justify-between">
            <label className="text-center text-sm text-title">
              {"Payment Type"}
            </label>
            <label className="text-center capitalize text-sm text-primary">{`${item.payment.gateway_name}`}</label>
          </div>
          <div className="flex py-3 w-full justify-between">
            <label className="text-left text-sm text-title">
              {"Transaction Date"}
            </label>
            <label className="text-right text-sm text-primary">
              {new Date(item.donation_date).toDateString() +
                " - " +
                FormatTime(new Date(item.donation_date))}
            </label>
          </div>
        </div>
      </div>
      <Button
        disabled={!item}
        title={"Download Receipt"}
        onClick={() => {
          window.open(BaseUrl + "/" + link);
          setOkay(true);
          setOpen(false);
        }}
      />
      <Button
        secondary
        title={"Done"}
        onClick={() => {
          setOkay(true);
          setOpen(false);
        }}
        extraClass={"mt-4"}
      />
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Dropdown, InputText } from "../../component";
// import IcRightArrow from "../../assets/icons/ic-right-arrow.svg";
import {
  GetDonationList,
  GetEventsList,
  // useDonationList,
  useOrgList,
} from "../../services/donation";
import { useNavigate } from "react-router-dom";
import { getWithExpiry, ToastMsg } from "../../utils";
import {
  useHeaderContext,
  useLoadingContext,
  useModalContext,
  // useModalContext,
} from "../../context";

export const Donation = () => {
  const navigate = useNavigate();
  const headerCtx = useHeaderContext();
  const member = getWithExpiry("member");
  const loadingCtx = useLoadingContext();
  const { setOpen, setOkay, ModalData } = useModalContext();

  const { data, isFetching } = useOrgList();
  const [showDollar, setShowDollar] = useState(false);
  const [minimumAmount, setMinimumAmount] = useState(3);
  const [donationCategories, setDonationCategories] = useState([]);
  const [donationCategoriesErr, setDonationCategoriesErr] = useState(false);
  const [selectedDonationCategory, setSelectedDonationCategory] = useState({
    category_name: "select",
  });
  const [donationEvent, setDonationEvent] = useState([]);
  const [donationEventErr, setDonationEventErr] = useState(false);
  const [selectedDonationEvent, setSelectedDonationEvent] = useState({
    event_name: "select",
  });
  const [donationOrg, setDonationOrg] = useState([]);
  const [donationOrgErr, setDonationOrgErr] = useState(false);
  const [selectedDonationOrg, setSelectedDonationOrg] = useState({
    display_name: "select",
  });
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventNameErr, setEventNameErr] = useState(false);

  const [extra, setExtra] = useState(0);

  useEffect(() => {
    loadingCtx.setLoading(false);
    headerCtx.setHeader("Donation");
    headerCtx.setMainHeader(true);
    headerCtx.setSearchBar(false);
    headerCtx.setIsBack(member ? false : true);
  }, []);

  const getDonation = async (id) => {
    const res = await GetDonationList({
      id: id,
    });
    if (res.status) {
      setDonationCategories(res.data);
      setExtra(extra + 1);
    } else {
      ToastMsg(res.message, "error");
    }
  };

  const getEvents = async (id) => {
    const res = await GetEventsList({
      id: id,
    });
    if (res.status) {
      setDonationEvent(res.data);
      setExtra(extra + 1);
    } else {
      ToastMsg(res.message, "error");
    }
  };

  useEffect(() => {
    if (!isFetching) {
      if (data?.status) {
        console.log("data", data, member);
        setDonationOrg(data.data);
        if (member) {
          setSelectedDonationOrg(member.organization);
          setAmount("");
          setSelectedDonationEvent({ event_name: "select" });
          setSelectedDonationCategory({ category_name: "select" });
          getEvents(member.organization.id);
        }
      } else {
        ToastMsg(data ? data.message : "something went wrong", "error");
      }
    } else {
      // ToastMsg("fetching...", "info");
    }
  }, [isFetching]);

  const ModalOutput = ({ onContinue }) => {
    return (
      <div className="p-5 bg-primaryBg max-w-2xl">
        <div
          className={`px-5 py-5 mb-2 shadow-md rounded-md bg-primaryCard flex flex-col`}
        >
          <label className="text-center text-base text-title">
            {`You are registered with `}
            <span className="font-bold text-primary">
              {`${member.organization.organization_title} `}
            </span>
            {`organization.`}
          </label>
          <label className="text-center text-base text-title">
            {`Are you sure you want to continue with `}
            <span className="font-bold text-primary">{`${selectedDonationOrg.organization_title} `}</span>
            {`organization?`}
          </label>
        </div>
        <div className="flex">
          <Button
            secondary
            title={"Cancel"}
            onClick={() => {
              setOkay(true);
              setOpen(false);
            }}
            extraClass={"mr-1"}
          />
          <Button
            title={"Continue"}
            onClick={() => {
              setOkay(true);
              setOpen(false);
              onContinue();
            }}
            extraClass={"ml-1"}
          />
        </div>
      </div>
    );
  };

  const onCheckFields = () => {
    let IsDisable = false;
    if (selectedDonationCategory?.category_name === "select") {
      IsDisable = true;
      setDonationCategoriesErr("Please select category");
    }
    if (selectedDonationOrg?.display_name === "select") {
      IsDisable = true;
      setDonationOrgErr("Please select organization");
    }
    if (selectedDonationEvent?.event_name === "select") {
      IsDisable = true;
      setDonationEventErr("Please select event");
    }
    // if (
    //   selectedDonationEvent?.event_name === "Other" &&
    //   selectedDonationCategory?.category_name === "Other"
    // ) {
    //   if (eventName === "") {
    //     IsDisable = true;
    //     setEventNameErr("Please enter event name");
    //   }
    // }
    if (amount === "" || isNaN(amount) || Number(amount) <= 0) {
      IsDisable = true;
      setAmountErr("Please enter donation amount");
    } else if (amount > 3000 || amount < minimumAmount) {
      IsDisable = true;
      setAmountErr(`Please enter amount between $${minimumAmount} to $3000`);
    }
    if (!IsDisable) {
      const stateData = {
        donation_category_id: selectedDonationCategory.id,
        donation_event_id: selectedDonationEvent.id,
        organization_id: selectedDonationOrg.id,
        event_name: eventName,
        donation_amount: amount,
      };

      if (member) {
        console.log("MEMBER", member, selectedDonationOrg);
        if (member.organization.id !== selectedDonationOrg.id) {
          setOkay(false);
          setOpen(true);
          ModalData(
            <ModalOutput
              onContinue={() =>
                navigate("/donation/details", { state: stateData })
              }
            />
          );
        } else {
          navigate("/donation/details", { state: stateData });
        }
      } else {
        navigate("/donation/details", { state: stateData });
      }
    }
  };

  return (
    <div className="w-full flex flex-col items-center flex-1">
      <div
        className={`px-5 py-5 shadow-xl mb-5 rounded-xl bg-primaryCard max-w-2xl w-full`}
      >
        <label className={`text-title font-bold text-base`}>
          {"Donation Detail"}
        </label>
        <div className="mt-5" />
        <Dropdown
          disabled={donationOrg.length === 0}
          isError={donationOrgErr}
          items={donationOrg}
          onChange={(e) => {
            setDonationOrgErr(false);
            setDonationEventErr(false);
            setDonationCategoriesErr(false);
            setSelectedDonationOrg(e);
            setAmountErr(false);
            setAmount("");
            setSelectedDonationEvent({ event_name: "select" });
            setSelectedDonationCategory({ category_name: "select" });
            getEvents(e.id);
          }}
          value={selectedDonationOrg}
          showValue={selectedDonationOrg?.display_name}
          title={"Donation To*"}
        />
        <div className="mt-5" />
        <Dropdown
          items={donationEvent}
          disabled={donationEvent.length === 0}
          isError={donationEventErr}
          onChange={(e) => {
            setDonationEventErr(false);
            setDonationCategoriesErr(false);
            setSelectedDonationEvent(e);
            setAmountErr(false);
            setAmount("");
            setSelectedDonationCategory({ category_name: "select" });
            getDonation(e.id);
          }}
          value={selectedDonationEvent}
          showValue={selectedDonationEvent?.event_name}
          title={"Event Type*"}
        />

        <div className="mt-5" />
        <Dropdown
          isError={donationCategoriesErr}
          items={donationCategories}
          disabled={donationCategories.length === 0}
          onChange={(e) => {
            setDonationCategoriesErr(false);
            setMinimumAmount(e.minimum_donation_amount);
            setAmountErr(false);
            setAmount("");
            setSelectedDonationCategory(e);
          }}
          value={selectedDonationCategory}
          showValue={selectedDonationCategory?.category_name}
          title={"Donation For*"}
        />
        {selectedDonationEvent?.event_name === "Other" &&
          selectedDonationCategory?.category_name === "Other" && (
            <InputText
              value={eventName}
              isError={eventNameErr}
              placeholder="Enter event name"
              extraClassName={"mt-2"}
              onChange={(e) => {
                const re = /^[A-Za-z\s]*$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setEventNameErr(false);
                  setEventName(e.target.value);
                }
              }}
            />
          )}
        <div className="mt-5" />
        <div className="flex flex-col pb-5">
          <label className={`text-sm font-medium text-primary`}>
            Donation Amount*
          </label>
          <div className="flex w-full">
            {(showDollar || amount !== "") && (
              <label
                className={`border-b ${
                  amountErr
                    ? "border-error"
                    : showDollar
                    ? "border-lineColor"
                    : "border-gray-300"
                } text-sm text-greyOut mt-4 pb-2 pr-1`}
              >
                {"$"}
              </label>
            )}
            <InputText
              id={"text"}
              disabled={selectedDonationCategory.category_name === "select"}
              isError={amountErr}
              placeholder={
                selectedDonationCategory.category_name === "select"
                  ? ""
                  : `enter amount between $${minimumAmount} to $3000`
              }
              autoComplete={"new-password"}
              onFocus={() => {
                setShowDollar(true);
                amount === "" &&
                  ToastMsg(
                    `enter amount between $${minimumAmount} to $3000`,
                    "info"
                  );
              }}
              onBlur={() => {
                setShowDollar(false);
                if (amount !== "") {
                  setAmount(Number(amount).toFixed(2));
                }
              }}
              extraClassName={"mt-4 pb-2 w-full"}
              type={"number"}
              value={amount}
              onChange={(e) => {
                if (e.target.value <= 3000) {
                  setAmountErr(false);
                  setAmount(e.target.value);
                } else {
                  setAmountErr(
                    `Please enter amount between $${minimumAmount} to $3000`
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
      <Button
        // disabled={ButtonDisabled()}
        title={"Continue"}
        onClick={() => onCheckFields()}
        extraClass={"max-w-2xl w-full"}
      />
      {!member && (
        <Button
          // disabled={ButtonDisabled()}
          secondary={true}
          title={"Cancel"}
          onClick={() => navigate(-1)}
          extraClass={"max-w-2xl mt-5 w-full"}
        />
      )}
    </div>
  );
};
